.marker {
  width: 100px; /* Set the width of the container */
  height: 100px; /* Set the height of the container */
  border: 5px solid #333; /* Border thickness and color */
  border-radius: 50%; /* Rounded corners */
  display: flex; /* Flexbox layout */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin: 0 auto; /* Center the container on the page */
  background-color: black;
  padding: 10px;
  font-size: 24px;
}

.home-marker {
  width: 50px; /* Set the width of the container */
  height: 50px; /* Set the height of the container */
  border: 5px solid gray; /* Border thickness and color */
  border-radius: 50%; /* Rounded corners */
  display: flex; /* Flexbox layout */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin: 0 auto; /* Center the container on the page */
  background-color: gray;
  padding: 10px;
  font-size: 24px;
}

.marker h1 {
  margin: 0; /* Remove default margin from paragraph */
  font-size: 24px; /* Font size for the text */
}
