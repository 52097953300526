#verticalEmblaCarousel .embla__viewport {
  overflow: hidden;
  height: 592px;
  @media screen and (max-width: 750px) {
    height: 100px;
  }
}

#verticalEmblaCarousel .embla__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--slide-spacing);
  @media screen and (max-width: 750px) {
    flex-direction: row;
  }
}

#verticalEmblaCarousel .embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-height);
}

#verticalEmblaCarousel .embla__slide__number {
  height: 90px;
  display: flex;
  align-items: center;
}
