#horizontalEmblaCarousel .embla__viewport {
  overflow: hidden;
}
#horizontalEmblaCarousel .embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
#horizontalEmblaCarousel .embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
#horizontalEmblaCarousel .embla__slide__number {
  height: var(--slide-height);
  width: var(--slide-width);
}
