.triangle {
  position: absolute;
  padding: 0 6px;
  padding-bottom: 3px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #fafafa;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.5);
}

.triangle::after {
  z-index: -1;
  content: "";
  position: absolute;
  top: 85%;
  left: 10%;
  box-sizing: border-box;

  border: 5px solid #fff;
  border-color: transparent transparent #fff #fff;

  transform-origin: 0 0;
  transform: rotate(225deg);

  -webkit-box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.5);
}

.triangle::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 85%;
  left: 100%;
  box-sizing: border-box;

  border: 5px solid black;
  border-color: transparent transparent #fff #fff;

  transform-origin: 0 0;
  transform: rotate(225deg);
}

.location_text {
  font-size: 12px;
  color: rgb(71, 71, 71);
}

.world_map_container {
  margin: 0 auto;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
}

.world-map {
  width: 100%;
  height: 80vh;
}

.info-box {
  background-color: #090909;
  border-radius: 32px;
  padding: 20px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: max-content;
}

.info-box-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
}

.info-box-row:last-child {
  border: none;
}

.green-box {
  width: 24px;
  height: 24px;
  background-color: #34eb43;
}

.light-green-box {
  width: 24px;
  height: 24px;
  background-color: #34eb8f;
}

.dark-green-box {
  width: 24px;
  height: 24px;
  background-color: #556b2f;
}
.red-box {
  width: 24px;
  height: 24px;
  background-color: rgba(153, 49, 45, 1);
}

.dark-blue-box {
  width: 24px;
  height: 24px;
  background-color: #2c456b;
}

.blue-box {
  width: 24px;
  height: 24px;
  background-color: #4779c4;
}

.light-blue-box {
  width: 24px;
  height: 24px;
  background-color: #83aff0;
}
.purple-box {
  width: 24px;
  height: 24px;
  background-color: rgba(67, 26, 99, 1);
}

.pink-box {
  width: 24px;
  height: 24px;
  background-color: pink;
}

.orange-box {
  width: 24px;
  height: 24px;
  background-color: orange;
}

.yellow-box {
  width: 24px;
  height: 24px;
  background-color: yellow;
}

.info-box-row h2 {
  font-weight: 400;
  font-size: 18px;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .world_map_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .world-map {
    height: 40vh;
  }

  .info-box {
    position: relative;
    left: 0px;
  }

  .info-box-row h2 {
    font-weight: 400;
    font-size: 16px;
    margin-left: 10px;
  }
}
