.test {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin: 0; */
}

.test2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
