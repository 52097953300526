.network-map-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 20px;

  margin: 40px auto;
}

.network-map-box-1 {
  /* border: 1px solid rgba(255, 255, 255, 0.05); */
  width: 100%;
  height: 80vh;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .network-map-box {
    margin: 0px auto;
  }
}
